import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { userPreferenceProfileDefaults, UserPreferenceEnum } from '@/models/UserPreference';
import { BasicPreferenceForEditing, BasicPreferenceHelper, fetchUserPreferences, getUserPreference, TableHeaderPreferenceForEditing, TableHeaderPreferenceHelper, updateUserPreference } from '@/support/UserPreferencesHelper';
import DraggableContent from '@/components/draggable-content/DraggableContent.vue';
import { userPreferencesReportHeadersDefaultDefaults } from '@/support/ReportHeaders';

@Component<UserPreference>({
  components: {
    DraggableContent,
  },
})

export default class UserPreference extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected user!: User | null;

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = true;

  protected UserPreferenceEnum = UserPreferenceEnum;

  protected userPreferencesForEditing: UserPreferencesForEditing = {
    [UserPreferenceEnum.PROFILE]: [],
    [UserPreferenceEnum.REPORT_HEADERS__DEFAULT]: [],
  };

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    await this.fetchUserPreferences();
    this.isLoading = false;
  }

  // #endregion

  // #region Class methods: Handlers
  /**
  * ie.
  * protected toggleLoading(): void {
  *  this.isLoading = ! this.isLoading;
  * }
  */

  protected async onDragEndEvent<TPreference extends BasicPreferenceForEditing>(preferenceForEditing: TPreference[], key: UserPreferenceEnum): Promise<void> {
    switch (key) {
      case UserPreferenceEnum.PROFILE:
        this.userPreferencesForEditing[key] = BasicPreferenceHelper.getInstance().normalizeOrder(preferenceForEditing);
        await updateUserPreference(BasicPreferenceHelper.getInstance().getPreferenceForEditingToPreference(this.userPreferencesForEditing[key]), key);
        return;

      case UserPreferenceEnum.REPORT_HEADERS__DEFAULT:
        this.userPreferencesForEditing[key] = TableHeaderPreferenceHelper.getInstance().normalizeOrder(preferenceForEditing);
        await updateUserPreference(TableHeaderPreferenceHelper.getInstance().getPreferenceForEditingToPreference(this.userPreferencesForEditing[key]), key);
    }
  }

  // #endregion

  // #region Class methods: Helpers
  /**
  * ie.
  * protected toggleLoading(): void {
  *  this.isLoading = ! this.isLoading;
  * }
  */

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchUserPreferences(): Promise<void> {
    const userPreferences = await fetchUserPreferences();

    this.userPreferencesForEditing[UserPreferenceEnum.PROFILE] = BasicPreferenceHelper.getInstance().getCleanPreferencesForEditing(
      getUserPreference(userPreferences || [], UserPreferenceEnum.PROFILE) || [],
      userPreferenceProfileDefaults,
    );

    this.userPreferencesForEditing[UserPreferenceEnum.REPORT_HEADERS__DEFAULT] = TableHeaderPreferenceHelper.getInstance().getCleanPreferencesForEditing(
      getUserPreference(userPreferences || [], UserPreferenceEnum.REPORT_HEADERS__DEFAULT) || [],
      userPreferencesReportHeadersDefaultDefaults,
    );
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

interface UserPreferencesForEditing {
  [UserPreferenceEnum.PROFILE]: BasicPreferenceForEditing[];
  [UserPreferenceEnum.REPORT_HEADERS__DEFAULT]: TableHeaderPreferenceForEditing[];
}
