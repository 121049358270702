import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, userReportTabs, ReportTab, userRoles } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<UserSettings>({})

export default class UserSettings extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected user!: User | null;

  @Prop({ default: true })
  protected disabled!: boolean;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected userRoles = userRoles;

  protected isReadingMoreInfo = false;

  protected activeMoreInfo = {
    title: 'Meer info',
    description: 'Deze uitleg ontbreekt nog...',
  };

  // Report tabs
  protected reportTabs: ReportTab[] = userReportTabs;
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  // #endregion

  // #region Class methods

  protected openReadMoreDialog(description: string) {
    this.activeMoreInfo.description = description;
    this.isReadingMoreInfo = true;
  }

  protected smsItems: SmsItem[] = [
    {
      name: 'Geen SMS',
      value: 'never',
    },
    {
      name: 'Alleen bij annulering in de avond of weekend een SMS',
      value: 'off_hours',
    },
    {
      name: 'Altijd een SMS',
      value: 'always',
    },
  ];
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async updateUser(key: string, value: string | boolean): Promise<void> {
    if (! this.user) {
      return;
    }

    const payload: { [key: string]: string | boolean } = { [key]: value };

    await this.user
      .include(['contact', 'address', 'country', 'roles', 'supervisor', 'signature', 'department'])
      .update(payload)
      .then((user: User) => {
        this.$emit('update:user', user);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async updateDefaultTab(): Promise<void> {
    if (! this.user) {
      return;
    }

    await this.user
      .include(['contact', 'address', 'country', 'roles', 'supervisor', 'signature', 'department'])
      .update({
        default_tab: this.user.default_tab,
      })
      .then((user: User) => {
        this.$emit('update:user', user);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get isAuthManager(): boolean {
    return this.authUser.hasRole(userRoles.ManagerRoles);
  }

  protected get isManager(): boolean {
    return !! this.user?.hasRole(userRoles.ManagerRoles);
  }

  protected get isAuthPlanner(): boolean {
    return !! this.authUser?.is_planner;
  }

  protected get isAdmin(): boolean {
    return !! this.user?.hasRole(userRoles.AdminRoles);
  }

  protected get authUser(): User {
    return this.$store.state.Auth;
  }

  protected get isLoggedInUser(): boolean {
    return this.user?.uuid === this.authUser?.uuid;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

interface SmsItem {
  name: string;
  value: string;
}
