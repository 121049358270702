import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatDate } from '@/support/String';
import { getStyledStatusLog } from '@/support/ReportStatus';
import { debounce } from 'lodash';
import { TableMeta } from '@/components/data-table/DataTable';
import { ReportLog } from '@/models/ReportLog';

@Component<MiLogs>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
    concatenateString: (value: string) => {
      if (value.length >= 200) {
        return `${value.slice(0, 200)}...`;
      }
      return value;
    },
  },
})
export default class MiLogs extends Vue {
  @Prop({ default: () => [] })
  protected logs!: ReportLog[];

  @Prop({ default: 1 })
  protected page!: number;

  @Prop({ default: 5 })
  protected limit!: number;

  @Prop({ default: {} })
  protected visibility!: PaginationVisibility;

  // Logs pagination
  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected paginationLimit: number | null = null;

  protected paginationPage: number | null = null;

  protected limits = [5, 10, 15, 25, 50, 100];

  public paginationDebounce: Function = this.handlePagination();

  protected mounted() {
    this.initialize();
    this.paginationLimit = this.limit;
    this.paginationPage = this.page;
  }

  protected initialize() {
    this.total = this.logs[0] !== undefined ? (this.logs[0].meta as TableMeta).total : 0;
    this.lastPage = this.logs[0] !== undefined ? (this.logs[0].meta as TableMeta).last_page : 1;
    this.from = this.logs[0] !== undefined ? (this.logs[0].meta as TableMeta).from : 0;
    this.to = this.logs[0] !== undefined ? (this.logs[0].meta as TableMeta).to : 0;
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.paginationPage = page;
        this.updatePagination();
      },
      300,
    );
  }

  protected updatePagination() {
    this.$emit('paginationUpdate', this.paginationLimit, this.paginationPage);
  }

  protected setLogColor(type: string) {
    switch (type) {
      case 'change':
        return 'red';
      case 'message':
        return 'orange';
      case 'status_change':
        return 'blue';
      case 'sync':
        return 'pink';

      default:
        break;
    }
  }

  protected concatenateString(value: string) {
    if (value.length >= 200) {
      return `${value.slice(0, 200)}...`;
    }
    return value;
  }

  protected parseNotification(reportLog: Log) {
    let parsed = '';

    if (reportLog && reportLog.text) {
      parsed = getStyledStatusLog(reportLog.text, reportLog.type);
    }

    return this.concatenateString(parsed);
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }
}

export interface Log {
  title: string;
  text: string;
  type: string;
}

export interface PaginationVisibility {
  total: boolean;
  limit: boolean;
  pagination: boolean;
}
