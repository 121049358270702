var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            { staticClass: "item__list", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "label", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [_vm._v("Bureau")]),
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Supervisor")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.organizations, function(organization, index) {
                return _c(
                  "v-flex",
                  {
                    key: index,
                    staticClass: "list__item",
                    attrs: { xs12: "" }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-center": "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _vm._v(" " + _vm._s(organization.name) + " ")
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.organizationUsers[organization.id],
                                "item-text": "name",
                                "item-value": "uuid",
                                "hide-details": "",
                                placeholder: "Kies een supervisor",
                                "search-input": organization.searchUser,
                                clearable: ""
                              },
                              on: {
                                keyup: function($event) {
                                  return _vm.searchUserFromOrganization(
                                    organization
                                  )
                                },
                                "update:searchInput": function($event) {
                                  return _vm.$set(
                                    organization,
                                    "searchUser",
                                    $event
                                  )
                                },
                                "update:search-input": function($event) {
                                  return _vm.$set(
                                    organization,
                                    "searchUser",
                                    $event
                                  )
                                },
                                change: function($event) {
                                  return _vm.updateSupervisor(organization)
                                },
                                "click:clear": function($event) {
                                  return _vm.deleteSupervisor(organization)
                                }
                              },
                              model: {
                                value: organization.supervisor,
                                callback: function($$v) {
                                  _vm.$set(organization, "supervisor", $$v)
                                },
                                expression: "organization.supervisor"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }