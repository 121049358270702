import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

import { Organization } from '@/models/Organization';
import { debounce } from 'lodash';
import { Model } from '@/models/Model';

@Component<UserSupervisor>({})
export default class UserSupervisor extends Vue {
  @Prop()
  protected user!: User | null;

  @Prop()
  protected userId!: string;

  protected isLoading = false;

  protected expertSearch = '';

  protected selectedOrganization = '';

  protected organizations: Organization[] = [];

  protected organizationUsers: {[key: string]: User[]} = {};

  protected count = 0;

  public searchUserFromOrganization: Function = this.handleSearchUserFromOrganization();

  protected mounted() {
    this.getOrganizations();
  }

  protected getOrganizations() {
    this.isLoading = true;
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
        this.count = organizations.length;

        organizations.forEach((organization: Organization) => {
          if (organization.id) {
            this.$set(this.organizationUsers, organization.id, []);
          }
        });

        this.organizations.forEach((organization: Organization) => {
          this.getUsersFromOrganization(organization);
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected searchUsersFromOrganization(organization: Organization) {
    if (! organization || ! organization.id) {
      return;
    }

    new User()
      .dmz(organization.id)
      .filter({
        search: organization.searchUser,
        type: 'expert',
      })
      .all()
      .then((users: User[]) => {
        if (organization.id) {
          this.$set(this.organizationUsers, organization.id, users);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getUsersFromOrganization(organization: Organization) {
    if (! organization || ! organization.id) {
      return;
    }

    new User()
      .dmz(organization.id)
      .filter({
        type: 'expert',
      })
      .all()
      .then((users: User[]) => {
        if (organization.id) {
          this.$set(this.organizationUsers, organization.id, users);
        }
        this.getSupervisorFromOrganization(organization);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getSupervisorFromOrganization(organization: Organization) {
    if (! organization || ! organization.id) {
      return;
    }

    new User()
      .dmz(organization.id)
      .include('supervisor')
      .find(this.userId)
      .then((user: User) => {
        if (user.supervisor && user.supervisor.name && organization.id) {
          const supervisor = new Model().dataToModel(user.supervisor, 'User');
          this.organizationUsers[organization.id].push(supervisor);
          organization.supervisor = user.supervisor;
        }

        this.count -= 1;
        if (this.count === 0) {
          this.isLoading = false;
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateSupervisor(organization: Organization) {
    if (! organization || ! organization.id || ! this.user) {
      return;
    }

    this.user
      .dmz(organization.id)
      .update({
        supervisor: organization.supervisor,
      })
      .then((user: User) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected deleteSupervisor(organization: Organization) {
    if (! organization || ! organization.id || ! this.user) {
      return;
    }

    this.user
      .dmz(organization.id)
      .update({
        supervisor: '',
      })
      .then((user: User) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleSearchUserFromOrganization() {
    return debounce((organization: Organization) => {
      this.searchUsersFromOrganization(organization);
    }, 300);
  }
}
