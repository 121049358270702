var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-timeline",
        { attrs: { dense: "", clipped: "" } },
        [
          _c(
            "v-timeline-item",
            {
              staticClass: "white--text mb-5",
              attrs: { "fill-dot": "", color: "mi-color-primary", large: "" }
            },
            [
              _c(
                "v-icon",
                { attrs: { slot: "icon", color: "white" }, slot: "icon" },
                [_vm._v("access_time")]
              ),
              _c(
                "h1",
                {
                  staticClass: "profile__userName profile__userName--timeline"
                },
                [_vm._v("Logs")]
              )
            ],
            1
          ),
          _vm.logs && _vm.logs.length
            ? _c(
                "v-slide-x-transition",
                { attrs: { group: "" } },
                _vm._l(_vm.logs, function(log) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: log.id,
                      staticClass: "mb-3",
                      attrs: { color: _vm.setLogColor(log.type), small: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-space-between": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("dateFormat")(log.created_at)) +
                                  " "
                              ),
                              log.report && log.report.case_number
                                ? [
                                    _vm._v(" in "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "timeline__link",
                                        on: {
                                          click: function($event) {
                                            return _vm.navigateToUrl(
                                              "/reports/" + log.report.uuid
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(log.report.case_number))]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", {
                              staticClass: "message__text",
                              domProps: {
                                innerHTML: _vm._s(_vm.parseNotification(log))
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-space-between": "",
                wrap: ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "", wrap: "" } },
                    [
                      _vm.visibility.total
                        ? _c("v-flex", { attrs: { shrink: "" } }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.from) +
                                  " - " +
                                  _vm._s(_vm.to) +
                                  " van " +
                                  _vm._s(_vm.total) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.visibility.limit
                        ? _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-select", {
                                attrs: { value: _vm.limit, items: _vm.limits },
                                on: { change: _vm.updatePagination },
                                model: {
                                  value: _vm.paginationLimit,
                                  callback: function($$v) {
                                    _vm.paginationLimit = $$v
                                  },
                                  expression: "paginationLimit"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.visibility.pagination
                ? _c(
                    "v-flex",
                    {
                      staticClass: "align-right",
                      attrs: { xs12: "", lg8: "" }
                    },
                    [
                      _c("v-pagination", {
                        attrs: { length: _vm.lastPage, "total-visible": 7 },
                        on: { input: _vm.paginationDebounce },
                        model: {
                          value: _vm.paginationPage,
                          callback: function($$v) {
                            _vm.paginationPage = $$v
                          },
                          expression: "paginationPage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }