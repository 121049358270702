var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-layout",
            { staticClass: "item__list", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "label", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [_vm._v("Actie")]),
                      _c("v-flex", { attrs: { xs6: "" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Standaard tab")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "text-right": "" } },
                        [
                          _c("v-select", {
                            staticClass: "pt-0",
                            attrs: {
                              items: _vm.reportTabs,
                              placeholder: "Standaard tab",
                              "item-text": "name",
                              "item-value": "value",
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateDefaultTab()
                              }
                            },
                            model: {
                              value: _vm.user.default_tab,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "default_tab", $$v)
                              },
                              expression: "user.default_tab"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("SMS ontvangen")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "text-right": "" } },
                        [
                          _c("v-select", {
                            staticClass: "pt-0",
                            attrs: {
                              items: _vm.smsItems,
                              placeholder: "SMS ontvangen",
                              "item-text": "name",
                              "item-value": "value",
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser("sms_preference", $event)
                              }
                            },
                            model: {
                              value: _vm.user.sms_preference,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "sms_preference", $$v)
                              },
                              expression: "user.sms_preference"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Gebruiker is planbaar")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0 float-right",
                            attrs: {
                              "hide-details": "",
                              name: "is_plannable",
                              color: "#a09b1b",
                              disabled: !_vm.isAuthManager || !_vm.isAuthPlanner
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser("is_plannable", $event)
                              }
                            },
                            model: {
                              value: _vm.user.is_plannable,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "is_plannable", $$v)
                              },
                              expression: "user.is_plannable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Beheert de planning")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0 float-right",
                            attrs: {
                              "hide-details": "",
                              name: "is_planner",
                              color: "#a09b1b",
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser("is_planner", $event)
                              }
                            },
                            model: {
                              value: _vm.user.is_planner,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "is_planner", $$v)
                              },
                              expression: "user.is_planner"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Is opnemer")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0 float-right",
                            attrs: {
                              "hide-details": "",
                              name: "is_opnemer",
                              color: "#a09b1b",
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser("is_opnemer", $event)
                              }
                            },
                            model: {
                              value: _vm.user.is_opnemer,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "is_opnemer", $$v)
                              },
                              expression: "user.is_opnemer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("Snelle vragenlijst")
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0 float-right",
                            attrs: {
                              "hide-details": "",
                              name: "has_quick_questions",
                              color: "#a09b1b",
                              disabled: _vm.disabled && !_vm.isLoggedInUser
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser(
                                  "has_quick_questions",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.user.has_quick_questions,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "has_quick_questions", $$v)
                              },
                              expression: "user.has_quick_questions"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v(" Real-time beschikbaarheid "),
                        _c(
                          "div",
                          { staticClass: "inline-block" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openReadMoreDialog(
                                                    "Wanneer je deze optie aanvinkt, wordt de ingevulde beschikbaarheid van dit profiel meteen aan de Planning van IMG doorgegeven."
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v(" info_outline ")]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [_c("span", [_vm._v("klik voor meer info")])]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0 float-right",
                            attrs: {
                              "hide-details": "",
                              name: "is_realtime_plannable",
                              color: "#a09b1b",
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateUser(
                                  "is_realtime_plannable",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.user.is_realtime_plannable,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "is_realtime_plannable", $$v)
                              },
                              expression: "user.is_realtime_plannable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$store.state.Auth.has_management_info
                ? _c(
                    "v-flex",
                    { staticClass: "list__item", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-center": "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _vm._v("Management info")
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 float-right",
                                attrs: {
                                  "hide-details": "",
                                  name: "has_management_info",
                                  color: "#a09b1b"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateUser(
                                      "has_management_info",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.user.has_management_info,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "has_management_info",
                                      $$v
                                    )
                                  },
                                  expression: "user.has_management_info"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.state.Auth.is_rockefeller
                ? _c(
                    "v-flex",
                    { staticClass: "list__item", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-center": "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _vm._v(
                              "Financiele Management Informatie (tarieven en kosten)"
                            )
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 float-right",
                                attrs: {
                                  "hide-details": "",
                                  name: "is_rockefeller",
                                  color: "#a09b1b"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateUser(
                                      "is_rockefeller",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.user.is_rockefeller,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "is_rockefeller", $$v)
                                  },
                                  expression: "user.is_rockefeller"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.state.Auth.has_expert_register &&
              _vm.user.hasRole(_vm.userRoles.ManagerRoles)
                ? _c(
                    "v-flex",
                    { staticClass: "list__item", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-center": "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _vm._v("Deskundigenregister")
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 pt-0 float-right",
                                attrs: {
                                  "hide-details": "",
                                  name: "has_expert_register",
                                  color: "#a09b1b"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateUser(
                                      "has_expert_register",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.user.has_expert_register,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "has_expert_register",
                                      $$v
                                    )
                                  },
                                  expression: "user.has_expert_register"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }