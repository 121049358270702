var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      _c("h2", { staticClass: "tw-text-2xl tw-font-semibold" }, [
        _vm._v("Volgorde elementen in zijpaneel")
      ]),
      !_vm.isLoading
        ? _c(
            "v-layout",
            { staticClass: "item__list", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "label", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs1: "" } }),
                      _c("v-flex", { attrs: { xs11: "" } }, [_vm._v("Blok")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "DraggableContent",
                {
                  attrs: {
                    draggables:
                      _vm.userPreferencesForEditing[
                        _vm.UserPreferenceEnum.PROFILE
                      ]
                  },
                  on: {
                    handleDragEndEvent: function($event) {
                      return _vm.onDragEndEvent(
                        $event,
                        _vm.UserPreferenceEnum.PROFILE
                      )
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    _vm._l(
                      _vm.userPreferencesForEditing[
                        _vm.UserPreferenceEnum.PROFILE
                      ],
                      function(userPreferenceForEditing, index) {
                        return _c(
                          "v-flex",
                          {
                            key: "userPreference-" + index,
                            staticClass: "list__item",
                            attrs: { xs12: "" }
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs1: "" } },
                                  [
                                    _c("v-icon", { staticClass: "sortIcon" }, [
                                      _vm._v("drag_indicator")
                                    ])
                                  ],
                                  1
                                ),
                                _c("v-flex", { attrs: { xs11: "" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        userPreferenceForEditing.displayNameForEditing
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c("h2", { staticClass: "tw-text-2xl tw-font-semibold tw-mt-16" }, [
        _vm._v("Volgorde kolommen bij alle rapporten")
      ]),
      !_vm.isLoading
        ? _c(
            "v-layout",
            { staticClass: "item__list", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "label", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs1: "" } }),
                      _c("v-flex", { attrs: { xs11: "" } }, [_vm._v("Blok")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "DraggableContent",
                {
                  attrs: {
                    draggables:
                      _vm.userPreferencesForEditing[
                        _vm.UserPreferenceEnum.REPORT_HEADERS__DEFAULT
                      ]
                  },
                  on: {
                    handleDragEndEvent: function($event) {
                      return _vm.onDragEndEvent(
                        $event,
                        _vm.UserPreferenceEnum.REPORT_HEADERS__DEFAULT
                      )
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    _vm._l(
                      _vm.userPreferencesForEditing[
                        _vm.UserPreferenceEnum.REPORT_HEADERS__DEFAULT
                      ],
                      function(userPreferenceForEditing, index) {
                        return _c(
                          "v-flex",
                          {
                            key: "userPreference-" + index,
                            staticClass: "list__item",
                            attrs: { xs12: "" }
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs1: "" } },
                                  [
                                    _c("v-icon", { staticClass: "sortIcon" }, [
                                      _vm._v("drag_indicator")
                                    ])
                                  ],
                                  1
                                ),
                                _c("v-flex", { attrs: { xs11: "" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        userPreferenceForEditing.displayNameForEditing
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }