import { Component, Vue } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { debounce, cloneDeep } from 'lodash';
import ErrorHandler from '@/support/ErrorHandler';
import { styles } from '@/support/Style';
import { Avatar } from '@/support/Avatar';
import MiPassword, { MiPasswordVisibility, MiPasswordIcons } from '@/components/mi-password/MiPassword';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import WorkHours from '@/components/Availability/WorkHours/WorkHours.vue';
import { User as UserModel, userTypes, includeUserTypes, getUserTypeName, userUrenadministratieLevelLabels, userRoles, UrenadministratieLevel } from '@/models/User';
import { ReportLog } from '@/models/ReportLog';
import UserSupervisor from '@/views/Users/UserSupervisor/UserSupervisor.vue';
import UserActions from '@/views/Users/UserActions/UserActions.vue';
import PostalCodeExclusion from '@/views/Users/Expert/PostalCodeExclusion/PostalCodeExclusion.vue';
import UserAreas from '@/views/Users/UserAreas/UserAreas.vue';
import { PaginationVisibility } from '@/components/mi-logs/MiLogs';
import { Department } from '@/models/Department';
import HourTariffMatrix from '@/components/HourTariffMatrix/HourTariffMatrix.vue';
import UserSettings from '@/views/Users/UserSettings/UserSettings.vue';
import UserPreference from '@/views/Users/UserPreference/UserPreference.vue';
import UserCommunication from '@/views/Users/UserCommunication/UserCommunication.vue';
import ExpertRegister from '@/views/Users/ExpertRegister/ExpertRegister.vue';
import ExpertRegisterSubmissionsDatatable from '@/views/ExpertRegisterSubmissions/ExpertRegisterSubmissionsDatatable/ExpertRegisterSubmissionsDatatable.vue';
import { Role } from '@/models/Role';
import MiLogs from '@/components/mi-logs/MiLogs.vue';
import { Rpc } from '@/models/Rpc';
import { ParsedTariffMatrix, parseMatrix, TariffMatrix, TariffUpdateMatrixPayload } from '@/components/HourTariffMatrix/HourTariffMatrix';

@Component<User>({
  components: {
    WorkHours,
    PostalCodeExclusion,
    UserAreas,
    UserActions,
    UserSettings,
    UserSupervisor,
    UserPreference,
    UserCommunication,
    ExpertRegister,
    ExpertRegisterSubmissionsDatatable,
    MiLogs,
    HourTariffMatrix,
  },
})
export default class User extends Vue {
  public $pageTitle = 'Gebruiker';

  // tabs
  // protected activeTab: string = 'tab-workhours';
  protected activeTab = 'tab-logs';

  protected activeTabHours = 'tab-hourly_rate';

  // Tariffs
  protected userUrenadministratieLevelLabels = userUrenadministratieLevelLabels;

  // User
  protected user: UserModel | null = null;

  protected userClone: UserModel | null = null;

  protected roles: Role[] = [];

  protected experts: UserModel[] = [];

  // Logs
  protected reportLogs: ReportLog[] = [];

  // User roles
  protected userRoles = userRoles;

  // Loadings
  protected isLoading = false;

  protected isLoadingCountries = false;

  protected isLoadingReportLogs = true;

  // Edit user
  protected isEditingUser = false;

  protected isUpdating = false;

  protected isSaved = false;

  protected debouncedSearchExperts: Function = debounce(this.handleSearchExperts, 300);

  // Others
  protected defaultDate = '0000-00-00 00:00:00';

  // Pagination
  protected paginationLimit = 25;

  protected paginationPage = 1;

  protected paginationVisibility: PaginationVisibility = {
    total: true,
    limit: true,
    pagination: true,
  };

  // File uploaded
  protected isFileUploaded = false;

  protected fileTypes: string[] = ['image/png', 'image/jpeg'];

  // Password
  protected isLoadingButton = false;

  protected isSavedButton = false;

  protected isChangingPassword = false;

  protected isPasswordValid = false;

  protected visibility: MiPasswordVisibility = {
    ruleList: false,
    showPassword: false,
  };

  protected icons: MiPasswordIcons = {
    password: 'lock_open',
    confirmation: 'lock_open',
  };

  // User types
  protected userTypes: UserType[] = userTypes;

  // Departments
  protected departments: Department[] = [];

  // Urenadministatie
  protected urenadministratieLevels: UrenadministratieLevelItem[] = Object.values(UrenadministratieLevel).map((level: string) => (
    { name: userUrenadministratieLevelLabels[`${level}`], value: level }
  ));

  protected matrix: TariffMatrix | null = null;

  protected parsedMatrix: ParsedTariffMatrix | null = null;

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getRoles();
    await this.getDepartments();
    await this.getUser();

    this.setActiveTab();
    this.isLoading = false;
  }

  protected setActiveTab() {
    this.activeTab = this.$route.query.tab ? `${this.$route.query.tab}` : this.activeTab;
  }

  protected handleUpdatedMatrix() {
    this.getTariffsMatrix();
  }

  protected async getTariffsMatrix() {
    if ((! this.organizationId || ! this.organizationId.length) && this.$store.state.isServiceOrganization) {
      return;
    }

    const response: null | AxiosResponse = await new Rpc()
      .dmz(this.organizationId)
      .rpcPost({
        signature: 'tariffs:get-matrix',
        body: {
          user_id: this.userId,
        },
      }, false);

    if (response && response.data) {
      this.matrix = response.data;
      this.parsedMatrix = parseMatrix(this.matrix as TariffMatrix);
    }
  }

  protected async getDepartments() {
    this.departments = await new Department().all().catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async getUser() {
    await new UserModel()
      .include(['contact', 'address', 'country', 'roles', 'supervisor', 'signature', 'department', 'token', 'experiences', 'fixed_skills', 'declaration_of_expertise', 'declaration_of_good_behaviour', 'organization'])
      .find(this.userId)
      .then((user: UserModel) => {
        this.user = user;
        this.initBreadcrumb();

        if (this.isSimpleUser) {
          this.userTypes = includeUserTypes(['simple', 'simple_plus']);
        }

        if (user.supervisor) {
          this.experts.push(user.supervisor);
        }

        if (user.department && (user.department as Department).id) {
          this.user.department = (user.department as Department).id;
        }

        this.getReportLogs();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getRoles() {
    const roles = await new Role()
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (roles) {
      this.roles = roles;
    }
  }

  protected getReportLogs(limit?: number, page?: number) {
    this.isLoadingReportLogs = true;

    new ReportLog()
      .filter({ user: this.userId })
      .include('report')
      .sort('created_at', 'DESC')
      .limit(limit || this.paginationLimit)
      .page(page || this.paginationPage)
      .all()
      .then((reportLogs: ReportLog[]) => {
        this.paginationLimit = limit || (this.paginationLimit as number);
        this.paginationPage = page || (this.paginationPage as number);
        this.reportLogs = reportLogs;
        this.isLoadingReportLogs = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleTabClicked(key: string) {
    if (key === 'tab-prices') {
      this.getTariffsMatrix();
    }
  }

  protected initBreadcrumb() {
    if (! this.user) {
      return;
    }

    const simpleUser = {
      name: 'Simple Gebruikersbeheer',
      path: '/instellingen/simple-gebruikersbeheer',
    };

    const user = {
      name: 'Gebruikersbeheer',
      path: '/instellingen/gebruikersbeheer',
    };

    this.$root.$emit('breadcrumbUpdated', {
      crumb: [
        this.isSimpleUser ? simpleUser : user,
        {
          name: this.user.name,
        },
      ],
    });
  }

  protected handleSearchExperts(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new UserModel()
      .filter('search', query.target.value)
      .limit(500)
      .all()
      .then((experts: UserModel[]) => {
        this.experts = experts;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private clearSaved() {
    this.isUpdating = false;
    this.isSaved = false;
    this.isEditingUser = false;
  }

  // Edit user
  protected editUser() {
    this.isEditingUser = true;
    this.userClone = cloneDeep(this.user);
  }

  protected resetUser() {
    if (! this.userClone) {
      return;
    }

    this.clearSaved();
    this.user = cloneDeep(this.userClone);
    this.userClone = null;

    this.isChangingPassword = false;
    this.resetMiPassword();
  }

  protected updateUser() {
    if (! this.user) {
      return;
    }

    if (this.user.supervisor && this.user.supervisor.uuid) {
      (this.user.supervisor as any) = this.user.supervisor.uuid;
    }
    if (this.user.supervisor === undefined) {
      (this.user.supervisor as any) = '';
    }

    if (this.user.department === undefined) {
      (this.user.department as any) = null;
    }

    this.user
      .update()
      .then(() => {
        this.resetUser();
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateLevel() {
    if (! this.user || ! this.user.urenadministratie_level) {
      return;
    }

    this.user
      .update({ urenadministratie_level: this.user.urenadministratie_level })
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // change signature
  protected uploadSignature(file: string) {
    if (file === null || ! this.user) {
      return;
    }

    this.isFileUploaded = true;
    const formData = new FormData();
    formData.append('signature', file);

    this.user
      .storeMedia(formData, this.user.uuid as string)
      .then(() => {
        const signatureUpload = this.$refs.signatureUpload as MiFileUpload;
        signatureUpload ? signatureUpload.uploaded() : null;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // change password
  protected canSubmit(password: string, confirmation: string) {
    if (! password || ! confirmation) {
      return false;
    }
    return password === confirmation;
  }

  protected changeOrUpdatePassword() {
    if (this.isChangingPassword) {
      this.changePassword();
      this.isLoadingButton = true;
    } else {
      this.isChangingPassword = true;
    }
  }

  protected get canSavePassword() {
    if (! this.isChangingPassword) {
      return true;
    }

    if (this.isChangingPassword && this.isPasswordValid) {
      return true;
    }

    return false;
  }

  protected changePassword() {
    if (! this.user) {
      return;
    }

    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;
    if (! miPassword) {
      return;
    }

    const password = miPassword.getPassword().password;
    const password_confirmation = miPassword.getPassword().confirmation;

    if (! this.canSubmit(password, password_confirmation)) {
      return;
    }

    this.isLoadingButton = true;
    this.user
      .update({ password, password_confirmation })
      .then(() => {
        this.isDelayingButtonFeedback();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected cancelEditPassword() {
    this.resetMiPassword();
    this.isChangingPassword = false;
  }

  protected isDelayingButtonFeedback() {
    setTimeout(() => {
      this.isLoadingButton = false;
      this.isSavedButton = true;
      this.isChangingPassword = false;

      setTimeout(() => {
        this.isSavedButton = false;
      }, 800);
    }, 1200);
  }

  protected resetMiPassword() {
    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;
    if (miPassword) {
      miPassword.reset();
      miPassword.resetRules();
    }
  }

  protected get userId(): string {
    return this.isMyProfile ? (this.$store.state.Auth as UserModel).getId() : this.$route.params.id;
  }

  protected get isMyProfile(): boolean {
    return this.$route.name === 'my-profile';
  }

  protected get isSimpleUser(): boolean | null {
    return this.user && this.user.hasRole(['simple', 'simple_plus']);
  }

  protected get isAdmin(): boolean {
    return this.$store.state.Auth && (this.$store.state.Auth as UserModel).hasRole(userRoles.AdminRoles);
  }

  protected get isManager(): boolean {
    return this.$store.state.Auth && (this.$store.state.Auth as UserModel).hasRole(userRoles.ManagerRoles);
  }

  protected get isPlanner(): boolean {
    return (this.$store.state.Auth as UserModel).is_planner || false;
  }

  protected get canEditWorkHours(): boolean {
    return this.userId === this.$store.state.Auth.uuid || this.$store.state.Auth.is_planner;
  }

  protected get isSelf() {
    return this.user?.uuid === this.$store.state.Auth.uuid;
  }

  protected get canEditUser(): boolean {
    if (this.$store.state.Auth.isAtabix) {
      return true;
    }

    if (this.isMyProfile || this.isServiceOrganization) {
      return !! this.isAdmin;
    }
    return this.isSimpleUser ? false : this.isAdmin;
  }

  protected get isServiceOrganization(): boolean {
    return this.$store.state.isServiceOrganization;
  }

  protected get userAvatar(): string {
    if (! this.user) {
      return '';
    }

    const fullName = `${this.user.first_name} ${this.user.last_name}`;
    return new Avatar(fullName || 'NA', { color: styles.white, background: styles.primary, size: 512 }).toDataUrl();
  }

  protected get userType(): string {
    if (! this.user || ! this.user.type) {
      return '';
    }

    return getUserTypeName(this.user.type);
  }

  get passwordButtonColor() {
    if (this.isChangingPassword) {
      return 'primary';
    }
    if (this.isSavedButton) {
      return 'success';
    }
    return 'warning';
  }

  protected get passwordButtonIcon() {
    if (this.isChangingPassword) {
      return 'save';
    }
    if (this.isSavedButton) {
      return 'check';
    }
    return 'lock';
  }

  get passwordButtonText() {
    if (this.isChangingPassword) {
      return this.$t('profile.password.save').toString();
    }

    if (this.isSavedButton) {
      return this.$t('profile.password.saved').toString();
    }

    return this.$t('profile.password.change').toString();
  }

  protected get tabs() {
    return [
      {
        title: 'Logs',
        key: 'tab-logs',
        visible: true,
      },
      {
        title: 'Communicatie',
        key: 'tab-communication',
        visible: true,
      },
      {
        title: 'Instellingen',
        key: 'tab-settings',
        visible: true,
      },
      {
        title: 'Voorkeuren',
        key: 'tab-preferences',
        visible: this.$store.state.Auth.uuid === this.user?.uuid,
      },
      {
        title: 'Competenties',
        key: 'tab-skills',
        visible: true,
      },
      {
        title: 'Gebieden',
        key: 'tab-areas',
        visible: true,
      },
      {
        title: 'Urenadministratie',
        key: 'tab-prices',
        visible: ! this.$store.state.isServiceOrganization && this.canEditWorkHours && ! this.isSimpleUser && this.$store.state.Auth.is_rockefeller,
        click: () => this.getTariffsMatrix(),
      },
      {
        title: 'Tijden',
        key: 'tab-workhours',
        visible: this.canEditWorkHours,
      },
      {
        title: 'Acties',
        key: 'tab-actions',
        visible: this.canEditUser,
      },
      {
        title: 'supervisor',
        key: 'tab-supervisor',
        visible: this.isSimpleUser && this.isAdmin && this.isServiceOrganization,
      },
      {
        title: 'Deskundigenregister',
        key: 'tab-deskundige-register',
        visible: ! this.isServiceOrganization && (this.isSelf || this.$store.state.Auth.has_expert_register) && ! this.user?.isSimpleUser,
      },
      {
        title: 'Deskundigenregister aanvragen',
        key: 'tab-deskundige-register-submissions',
        visible: ! this.isServiceOrganization && (this.isSelf || this.$store.state.Auth.has_expert_register) && ! this.user?.isSimpleUser,
      },
    ];
  }

  protected get organizationId(): string {
    return (this.$route?.query?.organization as string) || '';
  }
}

interface UserType {
  name?: string;
  value?: string;
}

interface UrenadministratieLevelItem {
  name?: string;
  value?: string;
}
