var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md5: "", lg4: "", xl3: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel profile" },
                        [
                          _vm.isLoading
                            ? _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "300px"
                                }
                              })
                            : _vm._e(),
                          !_vm.isLoading
                            ? [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    class: { editing: _vm.isEditingUser },
                                    attrs: { row: "", wrap: "" }
                                  },
                                  [
                                    _vm.canEditUser
                                      ? _c(
                                          "div",
                                          { staticClass: "iconContainer" },
                                          [
                                            !_vm.isEditingUser
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "iconContainer__edit"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "edit-icon",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editUser()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("edit")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isEditingUser
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "cancel-icon",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetUser()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("cancel")]
                                                  ),
                                                  !_vm.isUpdating
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "save-icon",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateUser()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("save")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isUpdating
                                                    ? _c("LoaderCard", {
                                                        staticClass:
                                                          "spinner--inline",
                                                        class: {
                                                          checked: _vm.isSaved
                                                        },
                                                        attrs: {
                                                          type: "spinner--small"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      { staticClass: "flex-starving" },
                                      [
                                        _c("img", {
                                          staticClass: "profile__avatar",
                                          attrs: { src: _vm.userAvatar }
                                        })
                                      ]
                                    ),
                                    _vm.user
                                      ? _c(
                                          "v-flex",
                                          [
                                            _vm.isEditingUser
                                              ? [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Voornaam"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.first_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "first_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.first_name"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Achternaam"
                                                    },
                                                    model: {
                                                      value: _vm.user.last_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "last_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.last_name"
                                                    }
                                                  })
                                                ]
                                              : _vm._e(),
                                            !_vm.isEditingUser
                                              ? [
                                                  _vm.user.last_login_at &&
                                                  _vm.user.last_login_at !==
                                                    _vm.defaultDate
                                                    ? _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "profile__last-login-at"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Laatst ingelogd: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormatTime"
                                                                  )(
                                                                    _vm.user
                                                                      .last_login_at
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _c(
                                                    "h1",
                                                    {
                                                      staticClass:
                                                        "profile__userName"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-light"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.user
                                                                .first_name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.user.last_name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile__userRoles"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.userType)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.user
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass: "profileContainer",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                "prepend-icon":
                                                  "supervised_user_circle",
                                                items: _vm.departments,
                                                "item-text": "name",
                                                "item-value": "id",
                                                label: "Afdeling",
                                                disabled: !_vm.isEditingUser,
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.user.department,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "department",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.department"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "person",
                                                label: "Naam op schade report",
                                                value: _vm.user.report_name,
                                                disabled: !_vm.isEditingUser
                                              },
                                              model: {
                                                value: _vm.user.report_name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "report_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.report_name"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "domain",
                                                label: "Bedrijfsnaam",
                                                value: _vm.user.company_name,
                                                disabled: !_vm.isEditingUser
                                              },
                                              model: {
                                                value: _vm.user.company_name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "company_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.company_name"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon":
                                                  "alternate_email",
                                                label: "Email",
                                                value: _vm.user.email,
                                                disabled: !_vm.isEditingUser
                                              },
                                              model: {
                                                value: _vm.user.email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.email"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "mail",
                                                label: "Bedrijf mail",
                                                value: _vm.user.business_email,
                                                disabled: !_vm.isEditingUser
                                              },
                                              model: {
                                                value: _vm.user.business_email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "business_email",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "user.business_email"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _vm.isEditingUser
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "prepend-icon":
                                                      "local_phone",
                                                    label: "Telefoonnummer",
                                                    value: _vm.user.phone
                                                  },
                                                  model: {
                                                    value: _vm.user.phone,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "phone",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.phone"
                                                  }
                                                })
                                              : _vm._e(),
                                            !_vm.isEditingUser
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-2 disabledIcon"
                                                    },
                                                    [_vm._v("local_phone")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "disabledLabel"
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v("Telefoonnummer")
                                                      ]),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "tel:" +
                                                              _vm.user.phone
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.user.phone
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        ),
                                        _vm.isEditingUser
                                          ? _c(
                                              "v-flex",
                                              { attrs: { sm12: "" } },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    "prepend-icon":
                                                      "assignment_ind",
                                                    items: _vm.userTypes,
                                                    "item-text": "name",
                                                    "item-value": "key",
                                                    label: "Type"
                                                  },
                                                  model: {
                                                    value: _vm.user.type,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.type"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                disabled: !_vm.isEditingUser,
                                                multiple: "",
                                                "prepend-icon":
                                                  "assignment_ind",
                                                items: _vm.roles,
                                                "item-text": "name",
                                                "item-value": "id",
                                                label: "Rollen"
                                              },
                                              model: {
                                                value: _vm.user.roles,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "roles",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.roles"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        !_vm.isSimpleUser
                                          ? _c(
                                              "v-flex",
                                              { attrs: { sm12: "" } },
                                              [
                                                _c("v-label", [
                                                  _vm._v("Supervisor")
                                                ]),
                                                !_vm.isEditingUser
                                                  ? _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.user
                                                              .supervisor &&
                                                              _vm.user
                                                                .supervisor.name
                                                              ? _vm.user
                                                                  .supervisor
                                                                  .name
                                                              : "-"
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm.isEditingUser
                                                  ? _c("v-autocomplete", {
                                                      attrs: {
                                                        items: _vm.experts,
                                                        "item-text": "name",
                                                        "item-value": "uuid",
                                                        "hide-details": "",
                                                        placeholder:
                                                          "Kies een supervisor",
                                                        clearable: ""
                                                      },
                                                      on: {
                                                        keyup:
                                                          _vm.debouncedSearchExperts
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.user.supervisor,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.user,
                                                            "supervisor",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.supervisor"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isEditingUser
                                          ? _c(
                                              "v-flex",
                                              { attrs: { sm12: "" } },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    label: "Gebruiker actief",
                                                    "hide-details": "",
                                                    name: "is_active",
                                                    color: "#a09b1b",
                                                    disabled: !_vm.isEditingUser
                                                  },
                                                  model: {
                                                    value: _vm.user.is_active,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "is_active",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.is_active"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isEditingUser
                                          ? _c(
                                              "v-flex",
                                              { attrs: { sm12: "" } },
                                              [
                                                _c("v-text-field", {
                                                  class: _vm.user.is_active
                                                    ? "icon-active"
                                                    : "icon-inactive",
                                                  attrs: {
                                                    "prepend-icon": _vm.user
                                                      .is_active
                                                      ? "check"
                                                      : "close",
                                                    value: _vm.user.is_active
                                                      ? "Gebruiker actief"
                                                      : "Gebruiker niet actief",
                                                    disabled: true,
                                                    "hide-details": ""
                                                  }
                                                }),
                                                _c("v-text-field", {
                                                  class: _vm.user.mfa_token
                                                    ? "icon-active"
                                                    : "icon-inactive",
                                                  attrs: {
                                                    "prepend-icon": _vm.user
                                                      .mfa_token
                                                      ? "check"
                                                      : "close",
                                                    value: _vm.user.mfa_token
                                                      ? "MFA-token ingesteld"
                                                      : "MFA-token niet ingesteld",
                                                    disabled: true,
                                                    "hide-details": ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            !_vm.isEditingUser
                                              ? [
                                                  _c("v-label", [
                                                    _vm._v("Handtekening")
                                                  ]),
                                                  !_vm.user.signature
                                                    ? _c(
                                                        "p",
                                                        { staticClass: "mb-0" },
                                                        [_vm._v("-")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "signatureContainer"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "signature",
                                                        attrs: {
                                                          src:
                                                            _vm.user.signature
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              : _vm._e(),
                                            _vm.isEditingUser
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "signatureContainer",
                                                    attrs: {
                                                      column: "",
                                                      "fill-height": "",
                                                      "pa-2": ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-label", [
                                                      _vm._v("Handtekening")
                                                    ]),
                                                    _c("MiFileUpload", {
                                                      ref: "signatureUpload",
                                                      attrs: {
                                                        clearAfterUpload:
                                                          _vm.isFileUploaded,
                                                        acceptedFileTypes:
                                                          _vm.fileTypes
                                                      },
                                                      on: {
                                                        itemDropped:
                                                          _vm.uploadSignature
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isEditingUser
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "profileContainer passwordContainer",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _vm.isChangingPassword
                                          ? [
                                              _c(
                                                "v-flex",
                                                { attrs: { sm12: "" } },
                                                [
                                                  _c("MiPassword", {
                                                    ref: "miPassword",
                                                    attrs: {
                                                      visibility:
                                                        _vm.visibility,
                                                      icons: _vm.icons
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.isPasswordValid,
                                                      callback: function($$v) {
                                                        _vm.isPasswordValid = $$v
                                                      },
                                                      expression:
                                                        "isPasswordValid"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        _c(
                                          "MiButton",
                                          {
                                            attrs: {
                                              color: _vm.passwordButtonColor,
                                              icon: _vm.passwordButtonIcon,
                                              iconColor: "white",
                                              disabled: !_vm.canSavePassword
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.changeOrUpdatePassword(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            !_vm.isLoadingButton
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.passwordButtonText
                                                    )
                                                  )
                                                ]
                                              : _vm._e(),
                                            _vm.isLoadingButton
                                              ? [
                                                  _c("LoaderCard", {
                                                    staticClass:
                                                      "spinner--inline button",
                                                    attrs: {
                                                      type: "spinner--small"
                                                    }
                                                  })
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        ),
                                        _vm.isChangingPassword
                                          ? _c(
                                              "v-flex",
                                              {
                                                attrs: { "text-xs-right": "" }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "bottom-link forgot",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.cancelEditPassword(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("general.cancel")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md7: "", lg8: "", xl9: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "slider-color": "secondary" },
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _vm._l(_vm.tabs, function(tab) {
                            return [
                              tab.visible
                                ? _c(
                                    "v-tab",
                                    {
                                      key: tab.key,
                                      staticClass: "tab__filled",
                                      class: {
                                        active: _vm.activeTab === tab.key
                                      },
                                      attrs: { href: "#" + tab.key },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleTabClicked(tab.key)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tab.title) + " ")]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-logs"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _vm.reportLogs && _vm.reportLogs.length
                                    ? [
                                        !_vm.isLoadingReportLogs
                                          ? _c("MiLogs", {
                                              attrs: {
                                                logs: _vm.reportLogs,
                                                limit: _vm.paginationLimit,
                                                page: _vm.paginationPage,
                                                visibility:
                                                  _vm.paginationVisibility
                                              },
                                              on: {
                                                paginationUpdate:
                                                  _vm.getReportLogs
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm.isLoadingReportLogs
                                    ? _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center",
                                          minHeight: "300px"
                                        }
                                      })
                                    : !_vm.reportLogs || !_vm.reportLogs.length
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            "Helaas zijn er geen logs voor deze gebruiker."
                                          )
                                        ])
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-communication"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserCommunication", {
                                    attrs: { user: _vm.user }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-settings"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserSettings", {
                                    attrs: {
                                      user: _vm.user,
                                      disabled: !_vm.canEditUser
                                    },
                                    on: {
                                      "update:user": function($event) {
                                        _vm.user = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-preferences"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserPreference", {
                                    attrs: { user: _vm.user }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-skills"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("Skills", {
                                    attrs: {
                                      type: "user",
                                      model: _vm.user,
                                      userId: _vm.userId
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-areas"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserAreas", {
                                    attrs: {
                                      user: _vm.user,
                                      userId: _vm.userId
                                    }
                                  }),
                                  _c("PostalCodeExclusion", {
                                    attrs: {
                                      user: _vm.user,
                                      userId: _vm.userId
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-prices"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "h2",
                                          { staticClass: "ml-0 header__title" },
                                          [
                                            _vm._v(
                                              "Prijsniveau voor deze gebruiker"
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "prepend-icon": "poll",
                                              items:
                                                _vm.urenadministratieLevels,
                                              "item-text": "name",
                                              "item-value": "value",
                                              label: "Standaard Tarief",
                                              "persistent-hint": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateLevel()
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.user
                                                  .urenadministratie_level,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "urenadministratie_level",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.urenadministratie_level"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("HourTariffMatrix", {
                                            attrs: { userId: _vm.userId },
                                            on: {
                                              updatedMatrix:
                                                _vm.handleUpdatedMatrix
                                            },
                                            model: {
                                              value: _vm.parsedMatrix,
                                              callback: function($$v) {
                                                _vm.parsedMatrix = $$v
                                              },
                                              expression: "parsedMatrix"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-workhours"
                              }
                            },
                            [
                              _vm.user
                                ? _c("WorkHours", {
                                    attrs: {
                                      canEdit:
                                        _vm.isManager &&
                                        _vm.$store.state.Auth.is_planner,
                                      user: _vm.user,
                                      userNote: _vm.user.schedule_comment,
                                      userId: _vm.userId
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-actions"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserActions", {
                                    attrs: {
                                      canEdit: _vm.canEditUser,
                                      user: _vm.user
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-supervisor"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("UserSupervisor", {
                                    attrs: {
                                      canEdit: _vm.canEditUser,
                                      user: _vm.user,
                                      userId: _vm.userId
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-deskundige-register"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c("ExpertRegister", {
                                    attrs: {
                                      user: _vm.user,
                                      organization: _vm.organizationId
                                    },
                                    on: { reload: _vm.initialize }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-deskundige-register-submissions"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pt-5 elementPanel elementPanel--user"
                                },
                                [
                                  _c("ExpertRegisterSubmissionsDatatable", {
                                    attrs: { user: _vm.user },
                                    on: { reload: _vm.initialize }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }